import {useTranslation} from "ni18n";
import React from "react";
import {useTypedSelector} from "src/store";

import {GA} from "../_services/api";
import Main from "../components/v5/Download/Main";
import Layout from "../components/v5/Layout";
import TestimonialSlider from "../components/v5/TestimonialSlider";
import styles from "./download.module.scss";

// export const config = {amp: "hybrid"};

const Download = () => {
  const i18n = useTranslation();
  const {isMobile} = useTypedSelector(({config}) => config);
  const gA = () =>
    isMobile
      ? GA({
          category: "Download app",
          action: "Branch Link from DL page",
          label: "",
        })
      : GA({
          category: "Download app",
          action: "SMS from DL page",
          label: "",
        });
  return (
    <Layout
      className={styles.download}
      pageTitle={i18n.t("Download | Carbon Health")}
      pageDescription={i18n.t(
        "Download | Providing High-Quality Adult & Pediatric Urgent Care both virtually or at one of our physical locations in California and Nevada.",
      )}
    >
      <Main {...{gA}} />
      <TestimonialSlider />
    </Layout>
  );
};
export default Download;
